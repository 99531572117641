:root {
  --main-primary: #527bcd;
  --white: white;
  --light-dark: #21252a;
  --transparent: transparent;
  --lighter-dark: #26293b;
}

.right-content .chat-box,
.right-content .div-block-549,
.right-content #mentor-placeholder {
  height: 100%;
}
.average-graph-container .tp_content.sho {
  display: none;
}
.show.w-select {
  background: transparent
    url('data:image/svg+xml;charset=utf-8,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11z"/></svg>')
    right -2px center no-repeat !important;
}

.callback-msg {
  font-size: 13px;
  padding: 8px 12px;
  margin-top: 15px;
  text-align: center;
  border-radius: 4px;
  display: none;
}

.callback-msg.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  display: block;
}

.callback-msg.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  display: block;
}

.af-view .popup_div-nm_popup {
  position: fixed;
}

.af-view .div-block-183 {
  flex-wrap: wrap;
  gap: 20px;
}

.af-view .div-block-256.export_to_csv {
  margin-left: 20px;
}

.report-spinner {
  margin-left: auto;
}

.pagination-container ul {
  list-style-type: none;
}

.w_per .div-block-177 .div-block-203.ph {
  display: block;
  height: auto;
  height: 8px;
}
.af-view .mob_nav{
  width: 100%;
  position: fixed;
}
.right-content.resize-max-point-reached{
  top: 60px!important;
}
@media screen and (max-width: 480px) {
  .af-view .div-block-2{
    display: none;
  }
}
@media screen and (min-width: 1170px) {
  .af-view .webpage-container{
    padding-right: 380px;
  }
}
@media screen and (max-width: 1169px) {
  .af-view .webpage-container{
    padding-right: 60px;
  }
}
@media screen and (max-width: 479px) {
  .af-view .mob_nav{
    overflow: unset;
  }
  .af-view .div-block{
    justify-content: space-between;
  }
  .af-view .link-block-17, .af-view .image{
    margin-left: 10px;
  }
  .af-view .s-input{
    width: 100%;
  }
  .af-view .form-block-4{
    width:100%;
  }
}
.modal.manage-account-modal.dark-mode-active .tabs-content{
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.modal.manage-account-modal.dark-mode-active .info-pill-label{
  color:#FFF;
}
.modal.manage-account-modal.dark-mode-active .info-pill-block{
  background-color: var(--accent, #282c34);
}
.modal.manage-account-modal.dark-mode-active .modal-body.manage-account-modal-body{
  background-image: linear-gradient(var(--accent), #282c3487);
}
.modal.manage-account-modal.dark-mode-active *:not(a, input, select, .modal-header-title){
  color: #ffffff;
}
.modal.manage-account-modal.dark-mode-active .users-settings-invite-modal .modal-container{
  background-color: #282c34;
}
.modal.manage-account-modal.dark-mode-active .default-btn.form-submit-btn{
  background-color: #878787;
}
.modal.manage-account-modal.dark-mode-active .default-btn.form-submit-btn:hover{
  background-color: #282c34;
}

.modal.manage-account-modal.dark-mode-active .table-pagination-btn-icon{
  filter: invert(1);
}
.modal.manage-account-modal.dark-mode-active .table-pagination-btn:hover{
  background-color: #878787;
}
.modal.manage-account-modal.dark-mode-active .table-pagination-btn:hover .table-pagination-btn-icon{
  filter:unset;
}
.modal.manage-account-modal.dark-mode-active .table-pagination-btn.table-pagination-btn-active{
  border: 1px solid #878787;
}
.modal.manage-account-modal.dark-mode-active .user-email-link{
  text-decoration-color: #FFF;
}
.modal.manage-account-modal.dark-mode-active nav.user-permission-dropdown-list.w-dropdown-list{
  display: none;
}
.modal.manage-account-modal.dark-mode-active nav.user-permission-dropdown-list.w-dropdown-list.w--open{
  display: block;
}
.modal.manage-account-modal.dark-mode-active .table-container .w-dropdown-list{
  background: #878787;
}
.modal.manage-account-modal.dark-mode-active .table-container .w-dropdown-list .w-dropdown-link{
  color: #FFF;
}
@media screen and (max-width: 479px) {
  .modal.manage-account-modal.dark-mode-active .account-user-details-block {
    background-image: linear-gradient(#282c34, rgb(129 129 129))!important;
  }
}
.modal.manage-account-modal.dark-mode-active .w-checkbox-input{
  margin: 4px 0 0 -20px;
}
form.no-arrow-after:after{
  content:""!important;
  margin-left: 0!important;
}
.predictive-graph-label{
  color:#9da5b4;
  font-size: 12px;
}
.react-select__menu{
  background-color: #21252b!important;
  z-index: 9999999999!important;
}
.react-select__single-value{
  color: white!important;
}
.react-select__option:hover, .react-select__option--is-focused, .react-select__option--is-selected{
  background: #527bcd!important;
}
.react-select__control{
  background-color: inherit!important;
}
.react-select__control{
  border-style: solid!important;
  border-color: #26293b!important;;
  border-radius: 4px!important;;
}
.react-select__multi-value{
  background-color: #527bcd!important;
}
.react-select__multi-value__label{
  color: white!important;
}
.react-select__multi-value__remove{
  cursor: pointer;
}
.react-select__multi-value__remove:hover{
  background: #527bcd!important;
  color: white!important;
  opacity: .7;
}
.hf_popup:has(.react-select__control){
  max-width: 448px;
}

.license-notification-block{
  padding: 10px;
  background-color: green;
  border-radius: 6px;
}
.license-notification-block.error{
  background-color: #e45757;
}
.cursor-pointer{
  cursor: pointer;
}
.ai-skills-btn.alt-link{
  background-color: transparent;
  border: solid 1px #527bcd;
}
.ai-skills-btn.alt-link:hover{
  background-color: #527bcd!important;
}
.form-field-helper{
  opacity: .8;
  font-size: 11px;
  margin-top: 5px;
}
:root{
  --sl-z-index-toast:99999999!important;
}
.ft-size-13{
  font-size: 13px!important;
}
sl-tooltip::part(body){
  background-color: var(--main-primary);
}
.main-content .table > .tr:hover{
  margin: 0 -12px;
  padding: 0 12px;
  background-color: var(--lighter-dark);

}
.flex-d-row{
  flex-direction: row!important;
}
.w-max-content{
  width: max-content!important;
}
.gap-10{
  gap: 10px!important;
}
.full-width{
  width: 100%;
}
.wrapped-ellipsis-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.af-view .div-block-205 .text-block-113{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-overflowable{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*DATE RANGE PICKER*/
.rdrDateDisplayWrapper, .rdrCalendarWrapper{
  background-color: #282c34!important;
}
.rdrDateDisplayItem{
  background-color: #21252b!important;
}
.rdrDay *:not(.rdrInRange, .rdrStartEdge, .rdrEndEdge), .rdrWeekDay, .rdrMonthAndYearPickers select{
  color:#FFF!important;
}
.rdrDayPassive *:not(.rdrInRange, .rdrStartEdge, .rdrEndEdge), .rdrDayDisabled{
  opacity: .7;
}
.rdrMonthAndYearWrapper {
  padding: 0 12px;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: var(--main-primary) !important;
}
.rdrDayDisabled{
  background-color: inherit!important;
}
.custom-submit-btn.disabled{
  background-color: #494949!important;
  border-color: #494949!important;
  cursor: not-allowed!important;
}
.wysiwig-toolbar-container *{
  color:#000!important;
}
.reactour__popover > span:first-child{
  top:-5px!important;
  background: var(--main-primary) !important;
}